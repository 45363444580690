/* App.css */

header {
  background-color: #333;
  color: white;
  padding: 20px;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1000;
}

.menu {
  list-style: none;
  display: flex;
  justify-content: space-around;
}

.menu li {
  position: relative;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  min-width: 160px;
  z-index: 1;
}

.dropdown:hover .dropdown-content {
  display: block;
}

.banner {
  margin-top: 80px; /* Add margin to avoid overlap with the header */
  position: relative;
  text-align: center;
}

.banner img {
  width: 100%;
  height: auto;
}

.banner-text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
  font-size: 2.5rem;
}
